<template>
  <div class="my-data">
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('10.2'))" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap">
        <div class="item">
          <div class="title">
            <span><t name="11.2" /></span>
            <div>{{ user.fullname }}</div>
          </div>
        </div>

        <div class="item">
          <div class="title">
            <span><t name="3.22" /></span>
            <div>{{ user.bin }}</div>
          </div>
          <n-icon icon="right-arrow" />
        </div>

        <div class="item">
          <div class="title">
            <span><t name="11.4" /></span>
            <div>{{ user.phone }}</div>
          </div>
          <n-icon icon="right-arrow" />
        </div>

        <div class="item">
          <div class="title">
            <span><t name="11.5" /></span>
            <div>{{ user.address }}</div>
          </div>
          <n-icon icon="right-arrow" />
        </div>

        <div class="item">
          <div class="title">
            <span><t name="11.6" /></span>
            <div>{{ user.name }}</div>
          </div>
          <n-icon icon="right-arrow" />
        </div>


        <n-button wide class="delete-acc" @click="deleteAcc">
          Удалить аккаунт
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageMyData',
  data() {
    return {
      user: $app.auth.user(),
    }
  },
  methods: {
    deleteAcc() {
      this.$var('load', true)
      $api.auth.deleteAcc().then(() => {
        $app.auth.logout()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.my-data {
  .delete-acc {
    margin-top: 15px;
    background-color: red;
    color: #FFFFFF;
  }
  .wrap {
    .item {
      padding: 12px 0;
      border-bottom: 1px solid #D9D9D9;
      position: relative;
      &:first-child{
        padding-top: 0;
      }

      .title {
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;

        span {
          font-size: 14px;
          color: #8C8C8C;
        }
      }

      .n-icon{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}
</style>
